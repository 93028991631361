import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import SEFTMTFATPageTemplate from '../components/SEFTMTFATPageTemplate'

import Layout from '../components/Layout'

const StaffPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <SEFTMTFATPageTemplate {...frontmatter} />
    </Layout>
  )
}

StaffPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default StaffPage

export const pageQuery = graphql`
  query IndexPage_Staff($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        meta {
          meta_title
          meta_description
        }
        hero {
          heading
          description
        }
        offerings {
          blurbs {
            image
            imagewidth
            heading
            text
            orientation
            link_url
            link_label
            checklist
          }
        }
        banner3 {
          heading1
          checklist
          ft_button {
            text
            url
          }
        }
      }
    }
  }
`
